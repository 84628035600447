<template>

  <div class="wrapper">
    <!--         知识付费落地页-->

    <headBack>
      <template v-slot:title>
        <div class='title'>
          详情
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
<!--    <div class="top">-->
<!--      <div class="left">-->
<!--        <img src="https://static.yihu365.cn/img/wx-uni/logo_kts.png" alt="">-->
<!--      </div>-->
<!--      <div class="center">-->
<!--        <div>医护到家</div>-->
<!--        <div class="bo_t">下载医护到家，了解更多健康知识</div>-->
<!--      </div>-->
<!--      <div class="right" @click="download">-->
<!--        去下载-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="f_height"></div>-->
    <div class="main">
      <div class="banner">
        <img :src="detail.HEAD_PIC_URL" alt="">
      </div>
      <div class="bti">
        <div class="tit">{{ detail.DESCRIPTION }}</div>
        <div class="sub" v-if="detail.productItemList">共{{detail.productItemList.length}}课时 ｜ {{detail.SALE_NUM}}人学习 </div>
      </div>
      <div class="audio_box">
        <div class="a_tit">
          <span class="st" :style="{background:`rgb(${detail.TOPIC_COLOR})`}">试听</span><span v-if="info.productItemList">{{audio.name}}</span>
        </div>
        <!--          <audio-->
        <!--              src="https://static.yihu365.cn/img/h5Img/MustangFord.mp3"-->
        <!--              controls-->
        <!--              loop-->
        <!--              @timeupdate="update()"-->
        <!--          ></audio>-->
        <audio v-if="detail.productItemList" :src="audio.src" ref="audioMedia"  @timeupdate="onTimeupdate"
               controls @canplay="onCanPlay">
        </audio>
        <div class="audio">
          <div class="slider">
            <span>{{audio.currentTime}}</span>
            <van-slider v-model="audio.sliderTime" @change="onChange" :active-color="color">
              <template #button>
                <div class="custom-button" :style="{background:`rgb(${detail.TOPIC_COLOR})`}" ></div>
              </template>
            </van-slider>
            <span>{{audio.maxTime}}</span>
          </div>
          <div class="play_box">
            <img @click="reduce" class="l_p" src="https://static.yihu365.cn/img/h5Img/after.png" alt="">
            <img @click='onPlay' class="c_p" :src="audio.playIcon" alt="">
            <img @click="add" class="r_p" src="https://static.yihu365.cn/img/h5Img/last.png" alt="">
          </div>
        </div>
      </div>
      <div class="kc_box">
        <van-tabs v-model:active="activeName" :title-active-color="color" :color="color">
          <van-tab title="课程介绍" name="a">
            <div class="jies">
              <div class="b_ti">
                <span class="k_u" :style="{background:`rgb(${detail.TOPIC_COLOR})`}"></span>
                <span>课程介绍</span>
              </div>
              <img v-for="(item,index) in detail.INFO_URL" class="j_img" :src="item" alt="">
              <div class="b_ti">
                <span class="k_u" :style="{background:`rgb(${detail.TABBAR_COLOR})`}"></span>
                <span>课程目录</span>
              </div>
              <div class="ml_list">
                <div class="list" :style="{background: aIndex == index? `rgb(${detail.TOPIC_COLOR})`:''}" :class="aIndex == index ? 'a-active': ''" v-for="(item,index) in detail.productItemList" @click="clickItem(item,index)">
                  <div class="txt">
                    <img v-if="index == 0" src="https://static.yihu365.cn/img/h5Img/suo_0.png" alt="">
                    <img v-else :src="isBuy ? 'https://static.yihu365.cn/img/h5Img/suo_0.png' :detail.LOCK_PIC " alt="">
                    <div>
                      <div class="bti2">{{item.ITEM_NAME}}</div>
                      <div>{{item.DURATION}}</div>
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="课程目录" name="b">
            <div class="jies">
              <div class="b_ti">
                <span class="k_u" :style="{background:`rgb(${detail.TOPIC_COLOR})`}"></span>
                <span>课程目录</span>
              </div>
              <div class="ml_list">
                <div class="list" :style="{background: aIndex == index? `rgb(${detail.TOPIC_COLOR})`:''}" :class="aIndex == index ? 'a-active': ''" v-for="(item,index) in detail.productItemList" @click="clickItem(item,index)">
                  <div class="txt">
                    <img v-if="index == 0" src="https://static.yihu365.cn/img/h5Img/suo_0.png" alt="">
                    <img v-else :src="isBuy ? 'https://static.yihu365.cn/img/h5Img/suo_0.png' :detail.LOCK_PIC ">
<!--                    <img v-else :src="audio.playIcon" alt="" >-->
                    <div>
                      <div class="bti2">{{item.ITEM_NAME}}</div>
                      <div>{{item.DURATION}}</div>
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </div>
          </van-tab>

        </van-tabs>
      </div>
    </div>

    <div class="bottom_fix" @click="download" :style="{background:`rgb(${detail.TABBAR_COLOR})`}">
      <div class="left">
         <p><span class="price" :style="{color:`rgb(${detail.PRICE_COLOR})`}">{{detail.PROMOTION_PRICE}}<span class="yu">元</span></span><span class="yj" :style="{color:`rgb(${detail.PAST_PRICE_COLOR})`,background:`rgb(${detail.PRICE_BACK_COLOR})`}">原价：{{detail.PRICE}}元</span></p>
         <div class="sub" :style="{color:`rgb(${detail.LESSION_COLOR})`}" v-if="detail.productItemList">共{{detail.productItemList.length}}课时 ｜ {{detail.SALE_NUM}}人学习 </div>
      </div>
      <div v-if='isBuy' class="btn" :style="{background:`rgb(${detail.LESSION_COLOR})`}">
        已购买
      </div>
      <div v-else class="btn" :style="{background:`rgb(${detail.LESSION_COLOR})`}" @click="toPay">
          立即购买
      </div>

    </div>
  </div>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import {reactive,ref} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {Toast} from "vant";
export default {
  name: "detail",
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const active_color = ref('')
    const detail = ref('');
    const aIndex = ref(0);
    const color = ref('#999')
    const audio= ref({
      name:'',
      src:'',
      playing: false,
      // 音频当前播放时长
      currentTime: "00:00",
      // 音频最大播放时长
      maxTime: "00:00",
      minTime: 0,
      step: 0.1,
      play: false, // 播放暂停按钮
      sliderTime:0,//进度百分比
      playIcon:''
    })

    const setColor = (color) => {
        return 'rgba('+color+')'
    }
    const getDetail = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductItemInfo",
        productId:route.query.productId,
        userId:store.state.uid,
        newversion:'2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res)=> {
        console.log('1',res.data);
        detail.value = res.data;
        active_color.value = setColor(res.data.TABBAR_COLOR)
        audio.value.playIcon= res.data.START_PIC
        color.value = setColor(res.data.TOPIC_COLOR)

        setWxShareDate(`医护到家：${res.data.NAME}`,res.data.HEAD_PIC_URL)//分享
        setMedia(res.data)
      })
    }
    getDetail()


    const isBuy = ref(false);
    const setMedia = (det) => {
      //已经购买
      if(det.isBoughtFlag == 1){
        isBuy.value =true;
      }else{//没有购买
        console.log(' det.productItemList[0]', det.productItemList[0]);
      }
      audio.value.name = det.productItemList[0].ITEM_NAME
      audio.value.src =  det.productItemList[0].SERVICE_INFO_URL
    }




    const audioMedia = ref(null)



    const info = ref('')

    const getInfo = () => {
      const params ={
        productId : route.query.productId
      }
      homeApi.getActivityProductItemInfo(params).then((res)=> {

        console.log(res.data)
        info.value = res.data

        // setWxShareDate(info.value.NAME,'https://static.yihu365.cn/img/wx-uni/logo_kts.png');// 分享
      })

    }
    getInfo();

    const onCanPlay = () => {
      audio.value.maxTime = realFormatSecond(audioMedia.value.duration)
    }
    // 开始播放
    const onPlay = ()=>{
      console.log(audioMedia.value.currentTime)
      console.log(audioMedia.value.duration)
      if(!audio.value.playing){
        audioMedia.value.play()
        audio.value.playing = true;
        audio.value.playIcon = detail.value.PAUSE_PIC
      }else{
        audioMedia.value.pause()
        audio.value.playing = false;
        audio.value.playIcon = detail.value.START_PIC
      }
    }
    //播放时
    const onTimeupdate = (res) => {
      // console.log(res.timeStamp)
      audio.value.currentTime = realFormatSecond(audioMedia.value.currentTime)
      audio.value.sliderTime= (audioMedia.value.currentTime / audioMedia.value.duration) * 100

    }
    const reduce = () => {
      let info = detail.value.productItemList
      if(isBuy.value){
        if(aIndex.value == 0){
          Toast({message:"已经是第一节了",duration:1000});
          return;
        }
        aIndex.value --;
        audio.value.name =info[aIndex.value].ITEM_NAME;
        audio.value.src =info[aIndex.value].SERVICE_INFO_URL;
        // audioMedia.value.play()
        setTimeout(()=> {
          audioMedia.value.play()
          audio.value.playing = true;
          audio.value.playIcon = detail.value.PAUSE_PIC
        },300)
        document.documentElement.scrollTop = 0;
      }else{
        // console.log('请购买解锁')
        Toast({message:"请购买解锁",duration:1000});
      }
      // audioMedia.value.currentTime -= 3
    }
    const add = () => {
      let info = detail.value.productItemList
      if(isBuy.value){
        if(aIndex.value == info.length - 1){
          Toast({message:"已经最后一节了",duration:1000});
          return;
        }
        aIndex.value ++;
        audio.value.name =info[aIndex.value].ITEM_NAME;
        audio.value.src =info[aIndex.value].SERVICE_INFO_URL;
        // audioMedia.value.play()
        setTimeout(()=> {
          audioMedia.value.play()
          audio.value.playing = true;
          audio.value.playIcon = detail.value.PAUSE_PIC
        },300)
        document.documentElement.scrollTop = 0;
      }else{
        // console.log('请购买解锁')
        Toast({message:"请购买解锁",duration:1000});
      }
      // audioMedia.value.currentTime += 3
    }
    const onChange = (data) => { //拖拽进度条
      const b = data / 100;
      audioMedia.value.currentTime = audioMedia.value.duration * b
    }
    // 将整数转换成 时：分：秒的格式
    const realFormatSecond=(second) =>{
      let secondType = typeof second;
      if (secondType === "number" || secondType === "string") {
        second = parseInt(second);
        let hours = Math.floor(second / 3600);
        second = second - hours * 3600;
        let mimute = Math.floor(second / 60);
        second = second - mimute * 60;
        // hours + ':' +
        return ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
      } else {
        return "0:00:00";
      }
    }


    const clickItem =(item,index)=> {
      console.log('item',item.SERVICE_INFO_URL)
      if(isBuy.value || index == 0){
        aIndex.value = index;
        audio.value.name =item.ITEM_NAME;
        audio.value.src =item.SERVICE_INFO_URL;
        // audioMedia.value.play()
        setTimeout(()=> {
          audioMedia.value.play()
          audio.value.playing = true;
          audio.value.playIcon = detail.value.PAUSE_PIC
        },300)

        document.documentElement.scrollTop = 0;
      }else{
        // console.log('请购买解锁')
        Toast({message:"请购买解锁",duration:1000});
      }



    }
    const toPay = () => {
          router.push({
            name:'communityOrder',
            query:{
              productId:route.query.productId
            }
          })

    }
    return {
      clickItem,
      getDetail,
      audio,
      onTimeupdate,
      onPlay,
      audioMedia,
      realFormatSecond,
      onCanPlay,
      onChange,
      reduce,
      add,
      info,
      toPay,
      isBuy,
      detail,
      aIndex,
      active_color,
      color
    }
  }
}
</script>

<style scoped lang="scss" >
.sss{

}
.a-active{
  box-sizing: border-box;
  padding-top: 20px;

  border-radius: 22px;
}
::v-deep(.van-tabs__line){
  //background-color:#FF9A9A;
}
::v-deep(.van-tab--active){
  //color:var(--active_color);
}
.wrapper{
  background: #F2F2F2;
  padding-bottom: 94px;
}
.top{
  background: #FFF0F0;
  width: 100%;
  height:110px;
  display: flex;
  font-size: 26px;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  left: 0;
  top:0;
  z-index: 100;
  color:#EE5254;
  box-shadow:0px 15px 20px -12px #FFF0F0;

  .left{
    img{
      width: 70px;
      height: 70px;
      margin:0 28px;
    }

  }
  .center{
    font-weight: bold;
    white-space: nowrap;
    flex-grow: 1;
    .bo_t{
      font-size: 25px;
      font-weight: normal;
    }
  }
  .right{
    width:90px;
    font-weight: bold;
    font-size: 30px;
    padding:0 60px;
    text-align: center;
    color:#fff;
    line-height: 80px;
    border-radius: 40px;
    background: #FFA6A6;
    margin-right: 10px;
    white-space: nowrap;
  }
}
.f_height{
  width: 100%;
  height:110px;
}
.main{
  .banner{
    box-shadow:0px 20px 10px 0px rgba(0,0,0,0.5);
    img{
      width: 100%;
    }
  }
  .bti{
    background: #fff;
    box-sizing: border-box;
    padding:26px 40px;
    .tit{
      color:#434343;
      font-size: 36px;
      font-weight: bold;
    }
    .sub{
      color:#919191;
      font-size: 28px;
    }
  }
  .audio_box{
    padding:20px 0;
    width: 96%;
    margin:16px auto;
    background: #FFFFFF;
    border-radius: 14px;
    font-size: 26px;
    color:#434343;
    .audio{

      box-sizing: border-box;
      padding:10px 20px;
      color: #919191;
      .slider{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ::v-deep(.custom-button){
          width: 22px;
          height: 22px;
          border-radius: 50%;

        }
        ::v-deep(.van-slider__bar){
          //background: #FF9A9A;
        }
        ::v-deep(.van-slider){
          width:480px;
          margin:0 15px;
        }
      }
      .play_box{

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 22px;
        .l_p,.r_p{
          width: 40px;
          height: 40px;
        }
        .c_p{
          width: 100px;
          height: 100px;
          margin:0 90px;
        }
        .r_p{}
      }
    }

    audio{
      display: none;
      margin-left: 20px;
      margin-bottom: 20px;
    }
    .a_tit{
      box-sizing: border-box;
      padding:10px 10px 10px 20px;
      .st{
        background: #FF9A9A;
        border-radius: 18px;
        display: inline-block;
        color:#fff;
        padding:3px 8px;
        margin-right: 10px;
      }
    }
  }
  .kc_box{
    background: #FFFFFF;
    .jies{
      font-size: 0;
      .b_ti{
        padding:20px 30px;
        font-size: 28px;
        font-weight: bold;
        .k_u{
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 36px;
          background:#FF9A9A ;
          margin-right: 10px;
        }
      }
      .j_img{
        width: 100%;
        font-size: 0;
      }
      .ml_list{
        box-sizing: border-box;
        padding:10px 30px 0px;
        font-size: 24px;
        .list{
          .txt{
            color:#434343;
            display: flex;
            padding-left: 20px;
            box-sizing: border-box;
            .bti2{
              font-size: 28px;
            }
          }
          img{
            width: 34px;
            height: 41px;
            margin-right: 20px;
          }
        }
        ::v-deep(.van-divider){
          margin:24px 0;
        }
      }
    }
  }

}
.bottom_fix{
  position: fixed;
  left:0;
  bottom:0;
  z-index: 100;
  height:120px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;

  box-shadow:0px -15px 20px -12px #FFF0F0;
  box-sizing: border-box;
  padding:0 30px;
  p{
     margin:0;
  }
  .sub{
    font-size: 28px;
    margin-top: 6px;
  }
  .btn{
    color:#fff;
    padding: 12px 40px;
    border-radius: 30px;
  }
  .price{
    color:#fff;
    font-size: 40px;
    .yu{
      font-size: 32px;
    }
  }
  .yj{
    font-size: 24px;
    display: inline-block;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 24px;
    padding:2px 16px;
  }

}
</style>
